
import * as ipxStaticRuntime$Oy88alvmMq from '/Users/cruiiz/Git/trueislamV5/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_rollup@4.17.2/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1280,
    "xxl": 1366,
    "2xl": 3072,
    "smx": 428,
    "2lg": 2048
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "main--alaskapro.netlify.app",
    "cp.trueislam.co.uk",
    "trueislam.local"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$Oy88alvmMq, defaults: {} }
}
        